import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { getTheme } from './theme';
import configureStore from './store';
import { setAppSettings } from './utils/app-settings.util';
// import { setupMock } from './app.mock';
import { axiosHelper } from './utils/axios/axios.helper';
import { setHistory } from './utils/history.util';
import { AppRootContainer } from './shared/app-root/app-root.container';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { SnackbarProvider } from 'notistack';
//Importing selected icons using treeshaking technic
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFootballBall,
  faCrown,
  faSackDollar,
  faMoneyBillWaveAlt,
  faShareSquare,
  faEnvelopeOpen,
  faComment,
  faStopwatch,
  faDice,
  faHorseHead,
  faGift,
  faUserFriends,
  faQuestion,
  faUser,
  faBars,
  faCheckCircle,
  faTimesOctagon,
  faUserEdit,
  faListOl,
  faCircleArrowLeft,
  faCircleArrowRight,
  faLayerPlus,
  faArrowDown,
  faArrowUp,
  faArrowPointer,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faCrown,
  faFootballBall,
  faStopwatch,
  faDice,
  faHorseHead,
  faGift,
  faUserFriends,
  faQuestion,
  faUser,
  faBars,
  faCheckCircle,
  faTimesOctagon,
  faUserEdit,
  faSackDollar,
  faMoneyBillWaveAlt,
  faShareSquare,
  faEnvelopeOpen,
  faComment,
  faListOl,
  faCircleArrowLeft,
  faCircleArrowRight,
  faLayerPlus,
  faArrowDown,
  faArrowUp,
  faArrowPointer,
);

export function main(appId: string, appSettings: any): any {
  setAppSettings(appSettings);
  const appAxios = Axios.create();
  axiosHelper.setAxios(appAxios);

  const history = setHistory();

  const initialState: any = {};
  const store: any = configureStore(initialState, history);

  const MOUNT_NODE = document.getElementById('root');
  if (!MOUNT_NODE) {
    return;
  }
  const darkMode = true;
  const render = (): void => {
    ReactDOM.render(
      <Provider store={store}>
        <ThemeProvider theme={getTheme(darkMode)}>
          <CssBaseline />
          <ConnectedRouter history={history}>
            <SnackbarProvider
              maxSnack={10}
              style={{
                backgroundColor: '#2d2d2d',
                color: 'white',
                border: '1px solid #c8a964',
                maxWidth: '500px',
                minWidth: '300px',
              }}
            >
              <AppRootContainer />
            </SnackbarProvider>
          </ConnectedRouter>
        </ThemeProvider>
      </Provider>,
      MOUNT_NODE,
    );
  };

  /* istanbul ignore next */
  // if (process.env.NODE_ENV === 'development') {
  //   if (module.hot) {
  //     module.hot.accept('./shared/app-root/app-root.component', () => {
  //       ReactDOM.unmountComponentAtNode(MOUNT_NODE);
  //       render();
  //     });
  //   }
  // }

  render();
}
