import { getAppSettings } from './app-settings.util';
import { axiosApi } from './axios/axios.helper';

export function vipApi(path: string, requestType: string, data: any, reqType?: string): any {
  const params = new FormData();
  Object.keys(data).forEach(key => {
    const value = data[key];
    params.append(key, value);
  });

  const headers = {
    'Content-Type': reqType === 'json' ? 'application/json' : 'multipart/form-data',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };

  const asiOptions = {
    method: requestType,
    headers,
    data: reqType === 'json' ? data : params,
  };

  const url = getAppSettings().apiUrl + path;

  return axiosApi(url, asiOptions);
}
