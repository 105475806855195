import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { IExternalAuth } from '../../../../models/login';
import { Loading } from '../../../../shared/loading/loading.component';
import { getHistory } from '../../../../utils/history.util';
import { styles } from '../ext-login-page/ext-login-page.component';
import { IAppSettingsDto } from '../../../../models/common';

export interface ExternalProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  loggedInUser: any;
  validToken: boolean | null;
  app_settings: IAppSettingsDto;
}
export interface ExternalActionProps {
  ExternalAuthAction: (login: IExternalAuth) => void;
}

export interface InternalProps {
  classes: any;
}

function ExternalAuthPage(
  props: InternalProps & ExternalProps & ExternalActionProps,
): React.ReactElement<InternalProps & ExternalProps & ExternalActionProps> {
  const { validToken, isLoading, ExternalAuthAction, app_settings } = props;

  const [loading, setLoading] = React.useState<boolean>(true);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let user: any = null;

  console.log('app_settings', app_settings);
  useEffect(() => {
    try {
      if (params.auth) params.token = params.auth;
      if (params.token) {
        localStorage.setItem('external_token', params.token);
        localStorage.setItem('site_id', app_settings.site.id.toString());

        ExternalAuthAction({
          external_username: params.username ?? '',
          site_id: app_settings.site.id,
          token: params.token,
          fingerprint: '',
          ip: '',
        });
      }
    } catch (error) {
      setLoading(false);
      getHistory().push(`/`);
    }
  }, []);

  useEffect(() => {
    try {
      if (validToken === true) getHistory().push(`/cashier`);
      user = localStorage.getItem('user');
      setLoading(false);
    } catch (error) {
      console.log(error);
      getHistory().push(`/`);
    }
  }, [validToken]);

  return (
    <>
      <Loading loading={isLoading || loading} url_logo={app_settings.logo.url} />
      {!user && !isLoading && !loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: 0,
          }}
        >
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            <Typography>Invalid Player [T]</Typography>
          </Alert>
        </div>
      )}
    </>
  );
}

export const ExternalAuthPageComponent = withStyles(styles)(ExternalAuthPage);
