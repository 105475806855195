import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { API_PATH } from '../../../constants';

const suffix = `/${userReducer.sliceName}/app`;

const GET_USER_INFO = `GET_USER_INFO${suffix}`;
export const getUserInfoAction = createAction(GET_USER_INFO);

const GET_USER_INFO_ERROR = `GET_USER_INFO_ERROR${suffix}`;
const getUserInfoErrorAction = createAction<any>(GET_USER_INFO_ERROR);

const GET_USER_INFO_SUCCESS = `GET_USER_INFO_SUCCESS${suffix}`;
const getUserInfoSuccessAction = createAction<any>(GET_USER_INFO_SUCCESS);

function* getUserInfoWatcher(): SagaIterator {
  yield takeEvery(GET_USER_INFO, getUserInfoWorker);
}
mergeSaga(getUserInfoWatcher);

function* getUserInfoWorker(): SagaIterator {
  try {
    if (localStorage.getItem('user') !== null || localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    const result = yield call(getApiPlayer);
    yield put(getUserInfoSuccessAction(result));
  } catch (e: any) {
    yield put(getUserInfoErrorAction(e));
  }
}

const getApiPlayer = (): Request => {
  return vipApi(API_PATH.USER.GET_USER_INFO, 'get', {});
};

const reduceHandlers = {
  [GET_USER_INFO]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    validToken: null,
  }),
  [GET_USER_INFO_SUCCESS]: (slice: UserSliceType, action: any): UserSliceType => {
    localStorage.setItem('user', JSON.stringify(action.payload.data));
    return {
      ...slice,
      isLoading: false,
      loggedInUser: action.payload.data,
      validToken: true,
    };
  },
  [GET_USER_INFO_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
    loggedInUser: false,
    validToken: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
