import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { showToast } from '../../../utils/toast/show-errors';
import { API_PATH } from '../../../constants';
import { getUserInfoAction } from './get-user-info.action';

const suffix = `/${userReducer.sliceName}/app`;

const UPDATE_PROFILE = `UPDATE_PROFILE${suffix}`;

export const UpdateProfileAction = createAction<string>(UPDATE_PROFILE);

const UPDATE_PROFILE_ERROR = `UPDATE_PROFILE_ERROR${suffix}`;

const UpdateProfileErrorAction = createAction<string>(UPDATE_PROFILE_ERROR);

const UPDATE_PROFILE_SUCCESS = `UPDATE_PROFILE_SUCCESS${suffix}`;

const UpdateProfileSuccessAction = createAction<string>(UPDATE_PROFILE_SUCCESS);

function* UpdateProfileWatcher(): SagaIterator {
  yield takeEvery(UPDATE_PROFILE, UpdateProfileWorker);
}

mergeSaga(UpdateProfileWatcher);

function* UpdateProfileWorker(action: any): SagaIterator {
  try {
    const result = yield call(getApiPlayer, action.payload);
    yield put(UpdateProfileSuccessAction(result));
    yield put(getUserInfoAction());
    yield call(showToast, result);
  } catch (e: any) {
    yield call(showToast, e.response.data);
    yield put(UpdateProfileErrorAction(e.response.data));
  }
}

const getApiPlayer = (data: any): Request => {
  return vipApi(API_PATH.USER.UPDATE_USER_INFO, 'post', data, 'json');
};

const reduceHandlers = {
  [UPDATE_PROFILE]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    updateProfileStatus: null,
  }),
  [UPDATE_PROFILE_SUCCESS]: (slice: UserSliceType): UserSliceType => {
    return {
      ...slice,
      isLoading: false,
      loggedInUser: null,
      updateProfileStatus: true,
    };
  },
  [UPDATE_PROFILE_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
    updateProfileStatus: false,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
