import React, { useEffect, useState } from 'react';
import { Button, Typography, createStyles, Grid, Divider, Paper, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { SiteIDEnum } from '../../../enum/site/site-id-enum';
import Alert from '@material-ui/lab/Alert';
import { getHistory } from '../../../utils/history.util';
import { Loading } from '../../../shared/loading/loading.component';
import { IAppSettingsDto } from '../../../models/common';

export interface ExternalProps {
  isLoading: boolean;
  user: any;
  app_settings: IAppSettingsDto;
}

export interface ExternalActionProps {
  getUserInfoAction: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'table',
      height: '100%',
      position: 'absolute',
      overflow: 'hidden',
      width: '100%',
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.jpg` + ')',
      backgroundSize: '1920px 1080px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'black',
      backgroundPosition: 'top center',
      paddingBottom: '4rem',
    },
    helper: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    tabPanel: {
      display: 'flex',
      textAlign: 'center',
      padding: '0px',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none!important',
    },
    bottomBtnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    successGrid: {
      marginTop: '0px',
      textAlign: 'center',
    },
    alert: {
      marginTop: '10px',
    },
    alertTitle: {
      width: '100%',
      fontSize: '1.1rem',
      '& .MuiAlert-message': {
        width: '100%',
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    fwTextCenter: {
      width: ' 100%',
      textAlign: 'center',
      marginTop: '10px',
    },
    fw: {
      width: ' 100%',
    },
    divCenter: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
    },
    orangeBox: {
      marginBottom: '1rem',
      borderRadius: '5px',
      padding: '.5rem',
      width: '90%',
      margin: 'auto',
      textAlign: 'center',
      border: '1px solid orange',
    },
    greenBox: {
      marginBottom: '1rem',
      border: '1px solid green',
      borderRadius: '5px',
      padding: '.5rem',
    },
    loginButton: {
      marginBottom: '1.5rem',
      paddingLeft: 0,
    },
    content: {
      margin: '0 auto',
      maxWidth: '450px',
      backgroundColor: '#242424',
    },
    header: {
      backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/auth-header-bg.png` + ')',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px 5px 0px 0px',
    },
    main: {
      padding: '1.5rem',
    },
    bottomText: {
      marginTop: 5,
      textAlign: 'center',
      color: '#eee',
    },
  }),
);

export const CognitoFlowPageComponent = (props: ExternalProps & ExternalActionProps) => {
  const { isLoading, user, app_settings, getUserInfoAction } = props;

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getUserInfoAction();
  }, []);

  useEffect(() => {
    if (
      user &&
      user.site &&
      user.site != SiteIDEnum.VIPCLUB &&
      user.can_use_cashier != null &&
      (user.can_use_cashier.can_use_cashier == true || user.can_use_cashier.step != 3)
    ) {
      getHistory().push('/cashier');
    }
  }, [user]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Loading loading={isLoading} url_logo={app_settings.logo.url} />
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={8}>
          <header className={classes.header} style={isLoading ? {} : { borderTop: '4px solid #C7A763' }}>
            {isLoading && <LinearProgress color='primary' style={{ borderRadius: '5px 5px 0px 0px' }} />}
          </header>
          <main className={classes.main}>
            <Grid container alignContent='center' className={clsx(classes.successGrid)}></Grid>
            <Grid item xs={12} className={clsx(classes.tabPanel)}>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography variant={'h6'} gutterBottom>
                  Cognito Flow
                </Typography>
                <Divider style={{ marginBottom: '1rem' }} />
                {user &&
                  user.site &&
                  user.site != SiteIDEnum.VIPCLUB &&
                  user.can_use_cashier != null &&
                  user.can_use_cashier.can_use_cashier == false &&
                  user.can_use_cashier.step == 3 && (
                    <>
                      <Typography>{user.can_use_cashier.can_use_cashier_message}</Typography>

                      {user.can_use_cashier.params && user.can_use_cashier.params.cognito_url ? (
                        <>
                          <div className={classes.fwTextCenter}>
                            <Button
                              className={classes.bottomText}
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                window.open(user.can_use_cashier.params.cognito_url, '_blank');
                                setOpen(true);
                              }}
                            >
                              Open Cognito Flow
                            </Button>
                          </div>
                          <div className={classes.fwTextCenter}>
                            <Button
                              className={classes.bottomText}
                              variant='contained'
                              color='secondary'
                              size='small'
                              disabled={!open}
                              onClick={() => {
                                getHistory().push('/cashier');
                              }}
                            >
                              Refresh
                            </Button>
                            <Alert severity={`warning`} className={classes.alert}>
                              <small>Refresh the page after completing the form.</small>
                              <br />
                            </Alert>
                          </div>
                        </>
                      ) : (
                        <Alert severity={`error`} className={classes.alert}>
                          Something went wrong
                          <br />
                        </Alert>
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
          </main>
        </Paper>
        <div className={classes.bottomText}>
          <Typography variant='caption'>
            Cashier service <span dangerouslySetInnerHTML={{ __html: '&copy;' }} /> 2020-2023
          </Typography>
        </div>
      </div>
    </div>
  );
};
